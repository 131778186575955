// Generated by Framer (f4b44ab)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, SVG, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/dC8FTu0pa";

const cycleOrder = ["mOGMScbHq"];

const serializationHash = "framer-g2scw"

const variantClassNames = {mOGMScbHq: "framer-v-1s4xpao"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, title, width, ...props}) => { return {...props, YAb8970GI: title ?? props.YAb8970GI ?? "Page layout"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, YAb8970GI, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "mOGMScbHq", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className]

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-g2scw", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-1s4xpao", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"mOGMScbHq"} ref={ref ?? ref1} style={{...style}}><SVG className={"framer-lbyw9a"} data-framer-name={"interface_validation_check_circle"} layout={"position"} layoutDependency={layoutDependency} layoutId={"yInh1xr9f"} opacity={1} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 24 24\"><path d=\"M 12 0 C 5.373 0 0 5.373 0 12 C 0 18.627 5.373 24 12 24 C 18.627 24 24 18.627 24 12 C 23.992 5.376 18.624 0.008 12 0 Z M 17.861 10.061 L 11.261 16.661 C 10.979 16.942 10.598 17.1 10.2 17.1 C 9.802 17.1 9.421 16.942 9.139 16.661 L 6.139 13.661 C 5.553 13.075 5.553 12.125 6.139 11.539 C 6.725 10.953 7.675 10.953 8.261 11.539 L 10.2 13.478 L 15.739 7.939 C 16.325 7.353 17.275 7.353 17.861 7.939 C 18.447 8.525 18.447 9.475 17.861 10.061 Z\" fill=\"var(--token-556a66a6-7f4b-4118-b327-ed374e4a0f03, rgb(173, 31, 255)) /* {&quot;name&quot;:&quot;Primary&quot;} */\"></path></svg>"} svgContentId={491202138} withExternalLayout/><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-136iv46"} data-styles-preset={"dC8FTu0pa"} style={{"--framer-text-color": "var(--extracted-r6o4lv, var(--token-e0da01bd-4e0f-4d83-9396-a2ca91f1ef19, rgb(0, 0, 0)))"}}>Page layout</motion.p></React.Fragment>} className={"framer-1rtouse"} data-framer-name={"In this section, I’ll provide an overview of the Framer program and then we jump into setting up our file with colors, breakpoints, text styles, etc."} layoutDependency={layoutDependency} layoutId={"FGgr6d4ZB"} style={{"--extracted-r6o4lv": "var(--token-e0da01bd-4e0f-4d83-9396-a2ca91f1ef19, rgb(0, 0, 0))", "--framer-paragraph-spacing": "0px"}} text={YAb8970GI} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-g2scw [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-g2scw .framer-13eqc4a { display: block; }", ".framer-g2scw .framer-1s4xpao { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 16px; height: min-content; justify-content: center; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: 663px; }", ".framer-g2scw .framer-lbyw9a { flex: none; height: 24px; position: relative; width: 24px; }", ".framer-g2scw .framer-1rtouse { flex: 1 0 0px; height: auto; position: relative; white-space: pre-wrap; width: 1px; word-break: break-word; word-wrap: break-word; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-g2scw .framer-1s4xpao { gap: 0px; } .framer-g2scw .framer-1s4xpao > * { margin: 0px; margin-left: calc(16px / 2); margin-right: calc(16px / 2); } .framer-g2scw .framer-1s4xpao > :first-child { margin-left: 0px; } .framer-g2scw .framer-1s4xpao > :last-child { margin-right: 0px; } }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 24
 * @framerIntrinsicWidth 663
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]}}}
 * @framerVariables {"YAb8970GI":"title"}
 * @framerImmutableVariables true
 */
const FramerlnPnZ18r3: React.ComponentType<Props> = withCSS(Component, css, "framer-g2scw") as typeof Component;
export default FramerlnPnZ18r3;

FramerlnPnZ18r3.displayName = "Checkmark Item";

FramerlnPnZ18r3.defaultProps = {height: 24, width: 663};

addPropertyControls(FramerlnPnZ18r3, {YAb8970GI: {defaultValue: "Page layout", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerlnPnZ18r3, [...sharedStyle.fonts])